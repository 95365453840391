import React, { useEffect, useLayoutEffect } from "react";
import QRcode from "qrcode.react";
// // import generatePDF from "./zTUS } from "@koale/useworker";
import jsPDF from "jspdf";

// const generatePDF = ({ codes, imgs }) => {
//   // console.log("Processing", { codes, imgs });
//   // let qr = img;
//   //Dimension of A4 in pts: 210 × 297
//   let pdf = new jsPDF("p", "mm", "a4", true);
//   pdf.setFontSize(8);
//   var pageWidth = 210;
//   var pageHeight = 297;
//   var pageMargin = 10;

//   pageWidth -= pageMargin * 2;
//   pageHeight -= pageMargin * 2;

//   var cellMargin = 0;
//   var cellWidth = 50;
//   var cellHeight = 27.2;

//   var startX = pageMargin;
//   var startY = pageMargin;

//   const addQr = (obj) => {
//     // doc.getTextDimensions(item.Name); turncate or split string if you needed
//     const { qr, code } = obj;
//     const bundleProps = code.split("/");
//     if (startY >= pageHeight) {
//       pdf.addPage();
//       startY = pageMargin; // Restart height position
//     }

//     pdf.addImage(qr, "png", startX, startY, 20, 20, undefined, "FAST");

//     pdf.text(`${bundleProps[0]} - ${bundleProps[1]}`, startX + 22, startY + 3);
//     pdf.text(bundleProps[5], startX + 22, startY + 8);
//     pdf.text(bundleProps[4], startX + 22, startY + 13);
//     pdf.text(`${bundleProps[2]} - ${bundleProps[3]}`, startX + 22, startY + 17);
//     pdf.text(code, startX, startY + 23);
//     var nextPosX = startX + cellWidth + cellMargin;

//     if (nextPosX > pageWidth) {
//       startX = pageMargin;
//       startY += cellHeight;
//     } else {
//       startX = nextPosX;
//     }
//   };

//   imgs.forEach((obj, i) => {
//     addQr(obj);
//   });

//   // pdf.save();
//   // // console.log(pdf.output("arraybuffer"));

//   // // console.log(pdf.output("datauristring"));
//   // // console.log(pdf);
//   return pdf ? "process" : "error";
// };

// // var worker = new Worker(new URL("./generateQr.js", import.meta.url));

// // worker.addEventListener(
// //   "message",
// //   function (e) {
// //     // console.log("Worker said: ", e.data);
// //   },
// //   false
// // );

// const Test = ({ codes, setLoader }) => {
//   const [generatePDFF, { status: generatePDFFStatus, kill: killWorker }] =
//     useWorker(generatePDF, {
//       timeout: 50000, // 5 seconds
//       remoteDependencies: [
//         "https://cdnjs.cloudflare.com/ajax/libs/jspdf/2.5.1/jspdf.umd.min.js",
//       ],
//       localDependencies: () => [jsPDF],
//     });
//   let list = document.querySelectorAll(".qrcode");
//   let imgs = Array.from(list);
//   imgs = imgs.map((element, i) => {
//     // // console.log({ code: codes[i], qr: element.toDataURL() });
//     return { code: codes[i], qr: element.toDataURL() };
//   });

//   useEffect(() => {
//     generatePDFF({ codes, imgs })
//       .then((result) => {
//         // console.log(result);
//       })
//       .catch((e) => {
//         throw e;
//       });
//   }, []);

//   return <></>;
// };

const QRCodes = ({
  codes = [],
  name,
  loader,
  setLoader,
  setClicked,
  codeWithPiece = [],
}) => {
  // let list = document.querySelector(".qrcode");
  // let codess = codes.slice(0, 4);
  let codess = codeWithPiece.map((ele) => {
    let bundleProps = ele.split("/");
    return (
      bundleProps[0] +
      "/" +
      bundleProps[1] +
      "/" +
      bundleProps[2] +
      "/" +
      bundleProps[3] +
      "/" +
      bundleProps[4] +
      "/" +
      bundleProps[5]
    );
  });
  const generatePDF = () => {
    //Dimension of A4 in pts: 210 × 297
    let pdf = new jsPDF("p", "mm", "a4", true);
    pdf.setFontSize(10);
    var pageWidth = 210;
    var pageHeight = 297;
    var pageMargin = 10;

    pageWidth -= pageMargin * 2;
    pageHeight -= pageMargin * 2;

    var cellMargin = 1;
    var cellWidth = 67;
    var cellHeight = 36;

    var startX = pageMargin;
    var startY = pageMargin;

    const addQr = (obj) => {
      // doc.getTextDimensions(item.Name); turncate or split string if you needed
      const { qr, code, codeWithPiece } = obj;
      const bundleProps = codeWithPiece.split("/");
      if (startY >= pageHeight) {
        pdf.addPage();
        startY = pageMargin; // Restart height position
      }
      // rrrrrrrrrrrrrrrrriiiiiiiiiiiiiiiiiiiiiiiisssssssssssssssssssssssssssssshhhhhhhhhhhhhhhhhhhhhhhhhhhuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuu
      pdf.addImage(qr, "png", startX, startY, 21, 21, undefined, "FAST");

      pdf.text(`Style - ${name}`, startX + 25, startY + 3);
      pdf.text(
        `Bundle - ${bundleProps[1]}/${bundleProps[5]}`,
        startX + 25,
        startY + 9
      );
      //  - ${bundleProps[1]}
      // pdf.text(bundleProps[5], startX + 25, startY + 8);
      pdf.text(
        `Size - ${bundleProps[4]}/${bundleProps[6]}`,
        startX + 25,
        startY + 15
      );
      pdf.text(
        `${bundleProps[2]} - ${bundleProps[3]}`,
        startX + 25,
        startY + 21
      );
      pdf.text(code, startX, startY + 26);
      var nextPosX = startX + cellWidth + cellMargin;

      if (nextPosX > pageWidth) {
        startX = pageMargin;
        startY += cellHeight;
      } else {
        startX = nextPosX;
      }
    };

    let list = document.querySelectorAll(".qrcode");
    let imgs = Array.from(list);
    imgs.forEach((element, i) => {
      addQr({
        code: codess[i],
        qr: element.toDataURL(),
        codeWithPiece: codeWithPiece[i],
      });
    });

    pdf.save();
    setLoader(false);
  };

  useEffect(() => {
    generatePDF();
  }, []);

  useLayoutEffect(() => {
    // console.log("processing!");
  }, []);
  return (
    <div>
      {codess.map((code, i) => (
        <QRcode
          key={i}
          style={{ display: "none" }}
          value={code}
          className="qrcode"
        />
      ))}
      {/* {document.querySelector(".qrcode") && (
        <Test codes={codes} setLoader={setLoader} />
      )} */}
    </div>
  );
};

export default QRCodes;
