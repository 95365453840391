import { Routes, Route, Navigate } from "react-router-dom";
import { useState, useEffect } from "react";
// components
import Dashboard from "./pages/dashboard/dashboard";
import Login from "./pages/login/login";
import DeletePass from "./components/deletepassmodal/deletepass";
import RawMaterials from "./pages/rawmaterials/rawmaterials";
import Jobs from "./pages/jobs/jobs";
import AddEditProduct from "./pages/addEditProducts/addEditProducts";
import Bidders from "./pages/bidder/bidder";
import Detailpage from "./pages/detailpage/detailpage";
import Chatroom from "./pages/chatroom/chatroom";
import Tabss from "./components/tabs/tabs";
import AddWorker from "./components/addworker/addWorker";
import DataTable from "./pages/workers/workers";
import EditWorker from "./components/editworker/editWorker";
import Addcolormodal from "./components/addcolormodal/addcolor";
import AccordianTable from "./pages/accordiantable/accordiantable";
import Track from "./pages/track/track";
import PrivacyPolicy from "./pages/privacypolicy/privacypolicy";
// styles
import "./App.scss";

//redux
import { useDispatch, useSelector } from "react-redux";
import {
  checkLoginStatus,
  getSession,
  selectIsLoggedIn,
} from "./store/features/adminSlice";
import { getBidder } from "./store/features/teamOwnerSlice";

//drag and drop
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import Garbage from "./garbage";
import privacypolicy from "./pages/privacypolicy/privacypolicy";
import Curators from "./pages/curators/curators";
import BiddingPlayers from "./pages/biddingPlayers/biddingPlayers";
import Teams from "./pages/franchise/franchise";
import AddBidder from "./pages/addBidder/addBidder";
import AddFranchise from "./pages/addFranchise/addFranchise";
import EditBidder from "./components/editBidder/editBidder";
import AddCurator from "./pages/addCurator/addCurator";
import EditCurator from "./pages/editCurator/editCurator";
import AddBiddingPlayer from "./pages/addBiddingPlayer/addBiddingPlayer";
import EditBiddingPlayer from "./pages/editBiddingPlayer/editBiddingPlayer";
import { getFranchise } from "./store/features/franchiseSlice";
import { getBiddingPlayers } from "./store/features/biddingPlayerSlice";
import { getCurator } from "./store/features/curatorSlice";
import EditFranchise from "./pages/editFranchise/editFranchise";

const App = () => {
  const dispatch = useDispatch();
  dispatch(checkLoginStatus());

  const page = window.location.href.split("/");
  const isPrivacyPolicy = () => {
    for (let i = 0; i < page.length; ++i) {
      if (page[i].match("privacypolicy")) {
        return true;
      }
    }
    return false;
  };
  const isMobile = window.matchMedia(
    "only screen and (max-width: 1000px)"
  ).matches;

  let isLoggedin = useSelector(selectIsLoggedIn);

  useEffect(() => {
    dispatch(getBidder());
    dispatch(getFranchise());
    dispatch(getBiddingPlayers());
    dispatch(getCurator());
    dispatch(getSession());
  }, []);

  return (
    <DndProvider backend={HTML5Backend}>
      <div className="App">
        {/* {isMobile && !isPrivacyPolicy() ? (
          <div
            style={{
              padding: "7px",
              display: "flex",
              justifyContent: "center",
              marginTop: "47vh",
            }}
          >
            <h1
              style={{
                textAlign: "center",
                fontWeight: "bold",
                fontSize: "2rem",
              }}
            >
              THIS APP WORKS ONLY FOR DESKTOP
            </h1>
          </div>
        ) : ( */}
        <Routes>
          <Route path="/login" exact element={<Login />} />
          <Route path="/" element={<Dashboard />} />
          <Route path="/bidders" element={<Bidders />} />
          <Route path="/bidders/:id" element={<EditBidder />} />
          <Route path="/bidders/new" element={<AddBidder />} />
          <Route path="/biddingPlayers" element={<BiddingPlayers />} />
          <Route path="/biddingPlayers/new" element={<AddBiddingPlayer />} />
          <Route path="/biddingPlayers/:id" element={<EditBiddingPlayer />} />
          <Route path="/teams" element={<Teams />} />
          <Route path="/teams/new" element={<AddFranchise />} />
          <Route path="/teams/:id" element={<EditFranchise />} />
          <Route path="/curators" element={<Curators />} />
          <Route path="/curator/new" element={<AddCurator />} />
          <Route path="/curator/:id" element={<EditCurator />} />
          {/* <Route path="/privacypolicy" element={<PrivacyPolicy />} /> */}
        </Routes>
        {/* )} */}
      </div>
    </DndProvider>
  );
};

export default App;
