import React, { useEffect, useState } from "react";
import axios from "axios";
//scss
import AddBiddingPlayerStyle from "./addBiddingPlayer.module.scss";

//bootstrap
import { Form, Container, Row, Col } from "react-bootstrap";

//svg
import { ReactComponent as Save } from "../../resources/svg/save.svg";

//component
import ResponsiveDrawer from "../../components/responsiveDrawer/responsiveDrawer";
import Cbtn from "../../components/commonbutton/cbtn";
import SelectLabels from "../../components/dropdowns/1/dropdown1";
import Autocomplete from "../../components/autoComplete/autoComplete";
import Snackbar from "../../components/snackbar/snackbar";
//redux
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import LoadingScreen from "../../components/loadingSpinner/loadingScreen";
import CancelIcon from "@mui/icons-material/Cancel";
import instance from "../../store/network routes/instance";
import { selectStatus } from "../../store/features/adminSlice";
import {
  getFranchise,
  getFreeFranchise,
  selectfranchiseData,
  selectFreeFranchiseData,
} from "../../store/features/franchiseSlice";
import { createBiddingPlayer } from "../../store/features/biddingPlayerSlice";

function AddBiddingPlayer() {
  let dispatch = useDispatch();
  let navigate = useNavigate();
  const [biddingPlayerDetail, setBiddingPlayerDetail] = useState({
    name: "",
    nationality: "",
    role: "",
    marquee: false,
    uncapped: false,
    battingHanded: "",
    bowlingHanded: "",
    basePrice: "",
    elite: false,
    basePriceString: "",
    image: "",
  });
  const [error, setError] = React.useState("");
  const [showError, setShowError] = React.useState(false);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [franchiseOptions, setFranchiseOptions] = useState([]);
  let franchises = useSelector(selectFreeFranchiseData);

  const sendFile = async (file) => {
    let bodyFormData = new FormData();
    setDisableSubmit(true);
    bodyFormData.append("file", file);
    try {
      if (bodyFormData.get("file")) {
        let res = await instance({
          method: "email",
          url: "uploadFile",
          data: bodyFormData,
          headers: {
            "Content-Type": "multipart/form-data; ",
          },
        });
        if (res.data.statusCode === 200) {
          setDisableSubmit(false);
          return res.data.url;
        } else {
        }
      }
    } catch (e) {
      if (e.code === "ERR_NETWORK") {
        alert("File size should be less than 50mb");
        setDisableSubmit(false);
        document.getElementById("userIdProof").value = "";
        setBiddingPlayerDetail((prev) => ({
          ...prev,
          userIdProof: "",
        }));
      }
    }
  };

  const handleChange = async (e) => {
    let res = await sendFile(e.target.files[0]);
    if (res) {
      setBiddingPlayerDetail({
        ...biddingPlayerDetail,
        image: res,
      });
    }
  };

  const handleChange1 = async (e) => {
    let res = await sendFile(e.target.files[0]);
    if (res) {
      setBiddingPlayerDetail({
        ...biddingPlayerDetail,
        userIdProof: res,
      });
    }
  };

  const handleNumberFormChange = (e) => {
    let value = Number(e.target.value);
    if (value >= 0)
      if (e.target.name === "basePrice") {
        if (value < 100) {
          setBiddingPlayerDetail((prev) => ({
            ...prev,
            basePrice: value || e.target.value,
            basePriceString: `${value || e.target.value} L`,
          }));
        } else {
          setBiddingPlayerDetail((prev) => ({
            ...prev,
            basePrice: value || e.target.value,
            basePriceString: `${(value || e.target.value) / 100} CR`,
          }));
        }
      } else {
        setBiddingPlayerDetail((prev) => ({
          ...prev,
          [e.target.name]: value || e.target.value,
        }));
      }
  };

  const handleFormChange = (e, value) => {
    setBiddingPlayerDetail({
      ...biddingPlayerDetail,
      [e.target.name]: value || e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let validate = false;
    const {
      name,
      nationality,
      role,
      battingHanded,
      bowlingHanded,
      basePrice,
      basePriceString,
    } = biddingPlayerDetail;

    validate = Object.values({
      name,
      nationality,
      role,
      battingHanded,
      bowlingHanded,
      basePrice,
      basePriceString,
    }).every(Boolean);

    if (validate) {
      if (basePrice >= 20) {
        submitForm(biddingPlayerDetail);
      } else {
        setError("Minimum Base Price Should Be Greater Than 20");
        setShowError(true);
        validate = false;
      }
    } else {
      setError("Enter Full Details");
      setShowError(true);
      validate = false;
    }
  };

  const submitForm = async (data) => {
    const res = await dispatch(createBiddingPlayer(data));
    // console.log(res.payload);
    if (res.payload.statusCode === 200) {
      setBiddingPlayerDetail({
        name: "",
        nationality: "",
        role: "",
        marquee: false,
        uncapped: false,
        battingHanded: "",
        bowlingHanded: "",
        basePrice: "",
        elite: false,
        basePriceString: "",
        image: "",
      });
      navigate("/biddingPlayers");
    } else if (res.payload.statusCode === 400) {
      setError(res.payload.msg);
      setShowError(true);
    }
    return res;
  };

  // console.log(biddingPlayerDetail);
  const loadingStatus = useSelector(selectStatus);

  return (
    <>
      {loadingStatus === "Loading" && <LoadingScreen />}
      {loadingStatus === "fulfilled" && (
        <ResponsiveDrawer>
          <Snackbar
            show={showError}
            setShow={setShowError}
            errorMsg={error}
            location={{ vertical: "bottom", horizontal: "center" }}
          ></Snackbar>
          <Container className={AddBiddingPlayerStyle.container}>
            <Row className={AddBiddingPlayerStyle.Container}>
              <Col>
                <h1 className={AddBiddingPlayerStyle.mainHeading}>
                  Add Player
                </h1>
              </Col>
            </Row>
            <Row
              className={`${AddBiddingPlayerStyle.Container} ${AddBiddingPlayerStyle.formContainer}`}
            >
              <Form onSubmit={handleSubmit}>
                {/* <Row>
                  <Col>
                    <input
                      type="file"
                      className={AddBiddingPlayerStyle.customFileInput}
                      accept="image/*"
                      onChange={handleChange}
                    />
                    <div
                      style={{
                        background: biddingPlayerDetail.image
                          ? `url(${biddingPlayerDetail.image}) #CBCBCB center center/cover`
                          : "#CBCBCB",
                        height: "110px",
                        width: "110px",
                        borderRadius: "50%",
                        position: "absolute",
                        top: "-60px",
                        right: "70px",
                        zIndex: 2,
                      }}
                    />
                  </Col>
                </Row> */}
                <Row>
                  <Form.Group as={Col} className="mb-4" controlId="name">
                    <Form.Label className={AddBiddingPlayerStyle.label}>
                      Name
                    </Form.Label>
                    <Form.Control
                      className={AddBiddingPlayerStyle.control}
                      type="text"
                      name="name"
                      onChange={handleFormChange}
                      placeholder="Name"
                      value={biddingPlayerDetail.name}
                    />
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    className="mb-4"
                    controlId="battingHanded"
                  >
                    <Form.Label className={AddBiddingPlayerStyle.label}>
                      Batting
                    </Form.Label>
                    <Form.Control
                      className={AddBiddingPlayerStyle.control}
                      type="text"
                      name="battingHanded"
                      onChange={handleFormChange}
                      placeholder="Batting Handed"
                      value={biddingPlayerDetail.battingHanded}
                    />
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    className="mb-4"
                    controlId="bowlingHanded"
                  >
                    <Form.Label className={AddBiddingPlayerStyle.label}>
                      Bowling
                    </Form.Label>
                    <Form.Control
                      className={AddBiddingPlayerStyle.control}
                      type="text"
                      name="bowlingHanded"
                      onChange={handleFormChange}
                      placeholder="Bowling Handed"
                      value={biddingPlayerDetail.bowlingHanded}
                    />
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group as={Col} className="mb-4" controlId="Nationality">
                    <Form.Label className={AddBiddingPlayerStyle.label}>
                      Nationality
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Nationality"
                      name="nationality"
                      className={AddBiddingPlayerStyle.control}
                      value={biddingPlayerDetail.nationality}
                      defaultValue={0}
                      onChange={handleFormChange}
                    />
                  </Form.Group>
                  <Form.Group as={Col} className="mb-4" controlId="Base Price">
                    <Form.Label className={AddBiddingPlayerStyle.label}>
                      Base Price
                    </Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="Base Price"
                      name="basePrice"
                      className={AddBiddingPlayerStyle.control}
                      value={biddingPlayerDetail.basePrice}
                      defaultValue={0}
                      onChange={handleNumberFormChange}
                    />
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    className="mb-4"
                    controlId="Base Price String"
                  >
                    <Form.Label className={AddBiddingPlayerStyle.label}>
                      Base Price String
                    </Form.Label>
                    <Form.Control
                      className={AddBiddingPlayerStyle.control}
                      type="text"
                      name="basePriceString"
                      disabled={true}
                      // onChange={handleFormChange}
                      placeholder="Base Price String"
                      value={biddingPlayerDetail.basePriceString}
                    />
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group as={Col} className="mb-4" controlId="Role">
                    <Form.Label className={AddBiddingPlayerStyle.label}>
                      Role
                    </Form.Label>
                    <SelectLabels
                      name="role"
                      slist={[
                        { option: "Batsman" },
                        { option: "Bowling" },
                        { option: "All Rounder" },
                        { option: "WicketKeeper" },
                      ]}
                      active={biddingPlayerDetail.role}
                      setActive={(e) => {
                        setBiddingPlayerDetail({
                          ...biddingPlayerDetail,
                          role: e.target.value,
                        });
                      }}
                      mSelect="0px"
                      selectBg="#f5f5f5"
                    />
                  </Form.Group>
                  <Form.Group as={Col} className="mb-4" controlId="Marquee">
                    <Form.Label className={AddBiddingPlayerStyle.label}>
                      Marquee
                    </Form.Label>
                    <SelectLabels
                      name="marquee"
                      slist={[{ option: "Yes" }, { option: "No" }]}
                      active={biddingPlayerDetail.marquee ? "Yes" : "No"}
                      setActive={(e) => {
                        setBiddingPlayerDetail({
                          ...biddingPlayerDetail,
                          marquee: e.target.value === "Yes" ? true : false,
                          elite: !(e.target.value === "Yes") && false,
                          uncapped: !(e.target.value === "Yes") && false,
                        });
                      }}
                      mSelect="0px"
                      selectBg="#f5f5f5"
                    />
                  </Form.Group>{" "}
                  <Form.Group as={Col} className="mb-4" controlId="Elite">
                    <Form.Label className={AddBiddingPlayerStyle.label}>
                      Elite
                    </Form.Label>
                    <SelectLabels
                      name="elite"
                      slist={[{ option: "Yes" }, { option: "No" }]}
                      active={biddingPlayerDetail.elite ? "Yes" : "No"}
                      setActive={(e) => {
                        setBiddingPlayerDetail({
                          ...biddingPlayerDetail,
                          elite: e.target.value === "Yes" ? true : false,
                          marquee: !(e.target.value === "Yes") && false,
                          uncapped: !(e.target.value === "Yes") && false,
                        });
                      }}
                      mSelect="0px"
                      selectBg="#f5f5f5"
                    />
                  </Form.Group>
                  <Form.Group as={Col} className="mb-4" controlId="uncapped">
                    <Form.Label className={AddBiddingPlayerStyle.label}>
                      Uncapped
                    </Form.Label>
                    <SelectLabels
                      name="uncapped"
                      slist={[{ option: "Yes" }, { option: "No" }]}
                      active={biddingPlayerDetail.uncapped ? "Yes" : "No"}
                      setActive={(e) => {
                        setBiddingPlayerDetail({
                          ...biddingPlayerDetail,
                          uncapped: e.target.value === "Yes" ? true : false,
                          marquee: !(e.target.value === "Yes") && false,
                          elite: !(e.target.value === "Yes") && false,
                        });
                      }}
                      mSelect="0px"
                      selectBg="#f5f5f5"
                    />
                  </Form.Group>
                </Row>
                <Row>
                  <Col xl={6}>
                    <Cbtn
                      disabled={disableSubmit}
                      type="submit"
                      style={{
                        fontSize: "0.8rem",
                        backgroundColor: disableSubmit ? "grey" : "#0040A8",
                        color: disableSubmit ? "black" : "#fff",
                        margin: "auto",
                        float: "right",
                        borderRadius: "10px ",
                        width: "8rem",
                        fontFamily: "Poppins-SemiBold",
                        letterSpacing: "0px",
                        padding: "11px 34px",
                      }}
                    >
                      <Save
                        style={{ marginRight: "5px" }}
                        className={AddBiddingPlayerStyle.save}
                      />
                      Save
                    </Cbtn>
                  </Col>
                  <Col xl={6}>
                    <Cbtn
                      to="/biddingPlayers"
                      disabled={disableSubmit}
                      type="button"
                      style={{
                        backgroundColor: "rgb(57 42 42)",
                        color: "white",
                        margin: "auto",
                        fontSize: "0.8rem",
                        borderRadius: "10px ",
                        width: "8rem",
                        fontFamily: "Poppins-SemiBold",
                        letterSpacing: "0px",
                      }}
                    >
                      Discard
                    </Cbtn>
                  </Col>
                </Row>
              </Form>
            </Row>
          </Container>
        </ResponsiveDrawer>
      )}
    </>
  );
}

export default AddBiddingPlayer;
